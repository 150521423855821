import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Processes.scss';
import './Box.scss';
import { Box } from './Box';
import { Filter } from 'domain/models';
import moment from 'moment';
import { useManagedProcesses } from 'api/use-managed-processes';
import { BoxEmpty } from './BoxEmpty';
import { BoxLoading } from './BoxLoading';
import { Info } from './Info';

const COLUMN_WIDTH = 30;
const COLUMN_GAP = 6;
const COLUMN_MAX_HEIGHT = 180;
const X1_COLOR = '#7A48FF';
const X2_COLOR = '#00E697';

interface ProcessesDto {
  day: string;
  x1: number;
  x2: number;
}

interface Props {
  filter: Filter;
}
export const Processes = ({ filter }: Props) => {
  const { t } = useTranslation();
  const [dataMaxHeight, setDataMaxHeight] = useState(1);
  const { loading, data } = useManagedProcesses(
    filter.startDate,
    filter.endDate,
  );
  const DATA: ProcessesDto[] = data
    .sort((a, b) => moment(a.date).valueOf() - moment(b.date!).valueOf())
    .map(info => {
      const dayMoment =
        info.date != null
          ? moment(info.date).format('DD MMM').toString()
          : moment().toString();
      // const day = dayMoment.month() * 100 + dayMoment.date();
      return { day: dayMoment, x1: info.resource ?? 0, x2: info.waste ?? 0 };
    });

  useEffect(() => {
    setDataMaxHeight(Math.max(...DATA.map(d => Math.max(d.x1, d.x2))));
  }, [DATA]);

  let sumaX1 = 0;
  let sumaX2 = 0;
  for (const clave in DATA) {
    if (DATA.hasOwnProperty(clave)) {
      const proceso = DATA[clave];
      sumaX1 += proceso.x1;
      sumaX2 += proceso.x2;
    }
  }
  const empty = sumaX1 === 0 && sumaX2 === 0 ? true : false;

  return (
    <>
      <div className="chartBox" style={{ height: 326 }}>
        <label>{t('dashboard.charts.processes.title')}</label>
        <Info
          title={t('dashboard.charts.processes.title')}
          text={t('dashboard.charts.processes.info')}
        />

        {loading ? (
          <BoxLoading />
        ) : empty ? (
          <BoxEmpty />
        ) : (
          <Box
            columnGap={COLUMN_GAP}
            columnWidth={COLUMN_WIDTH}
            dataLength={DATA.length}
            yLabel={t('dashboard.charts.processes.yLabel')}
            yBottom={t('dashboard.charts.processes.yBottom')}
            legend={[
              {
                label: t('dashboard.charts.processes.xLabel1'),
                color: X1_COLOR,
              },
              {
                label: t('dashboard.charts.processes.xLabel2'),
                color: X2_COLOR,
              },
            ]}
          >
            {DATA.map((d, index) => {
              const heightX1 = Math.round(
                (COLUMN_MAX_HEIGHT * d.x1) / dataMaxHeight,
              );
              const heightX2 = Math.round(
                (COLUMN_MAX_HEIGHT * d.x2) / dataMaxHeight,
              );
              return (
                <div
                  key={index}
                  className="processesColumn"
                  style={{ width: COLUMN_WIDTH }}
                >
                  <div className="processesColumnIn">
                    <div style={{ width: COLUMN_WIDTH / 2 }}>
                      <div className="number">{d.x1 !== 0 && d.x1}</div>
                      <div
                        className="color"
                        style={{ height: heightX1, backgroundColor: X1_COLOR }}
                      />
                    </div>
                    <div style={{ width: COLUMN_WIDTH / 2 }}>
                      <div className="number">{d.x2 !== 0 && d.x2}</div>
                      <div
                        className="color"
                        style={{ height: heightX2, backgroundColor: X2_COLOR }}
                      />
                    </div>
                  </div>
                  <div className="label">
                    <p>{d.day}</p>
                  </div>
                </div>
              );
            })}
          </Box>
        )}
      </div>
    </>
  );
};
