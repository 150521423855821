import { RefObject, createRef, useEffect, useState } from 'react';

export const useResizeWidth = (): [RefObject<HTMLDivElement>, number] => {
  const ref = createRef<HTMLDivElement>();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const element = ref.current;

    if (element == null) return;

    const observer = new ResizeObserver(entries => {
      setWidth(entries[0].contentRect.width);
    });
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, ref]);

  return [ref, width];
};
