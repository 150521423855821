import { ReactNode, useEffect, useState } from 'react';

import { ReactComponent as ChevronRight } from 'assets/icons/ChevronRight.svg';

import './Box.scss';
import { useResizeWidth } from 'hooks';

interface Props {
  children: ReactNode;
  columnGap: number;
  columnWidth: number;
  dataLength: number;
  yLabel: string;
  yBottom: string;
  legend?: { label: string; color: string }[];
  yAxis?: number[];
  tooltip?: {
    index: number;
    x: number;
    data: { value: number; color: string }[];
  };
  isEvolution?: boolean;
  onWidth?: (width: number) => void;
}
export const Box = ({
  children,
  columnGap,
  columnWidth,
  dataLength,
  yLabel,
  yBottom,
  legend,
  yAxis,
  tooltip,
  isEvolution = false,
  onWidth,
}: Props) => {
  const [position, setPosition] = useState(0);

  const [chartRef, newWidth] = useResizeWidth();

  useEffect(() => {
    setPosition(0);
    if (onWidth && newWidth > 0) onWidth(newWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newWidth]);

  const handlePosition =
    (isLeft: boolean = false) =>
    () => {
      setPosition(prev => {
        const pos = isLeft ? prev - 1 : prev + 1;
        return pos < 0 ? 0 : pos > maxPos ? maxPos : pos;
      });
    };

  const step = Math.floor((newWidth + columnGap) / (columnWidth + columnGap));
  const maxPos = step > 0 ? Math.ceil(dataLength / step) - 1 : 0;
  let left = -position * step * (columnWidth + columnGap);

  const isLastPos = maxPos > 0 && position === maxPos;
  if (isLastPos) {
    const dataWidth = dataLength * (columnWidth + columnGap);
    const stepWG = step * (columnWidth + columnGap);
    left = left - dataWidth + stepWG * (position + 1);
  }
  let newColumnGap = columnGap;
  if (maxPos === 0) {
    newColumnGap = (newWidth - dataLength * columnWidth) / (dataLength - 1);
  }

  const buttonHeight = isEvolution ? { height: 190 } : undefined;
  return (
    <>
      {legend != null && <Legend data={legend} />}
      <div className="wrapper">
        <div className="y-axis">
          <div className="y-label" style={buttonHeight}>
            <div>{yLabel}</div>
          </div>
          <span>{yBottom}</span>
        </div>
        <div className="button_left" style={buttonHeight}>
          {maxPos > 0 && (
            <button onClick={handlePosition(true)} disabled={position === 0}>
              <ChevronRight />
            </button>
          )}
        </div>
        {yAxis != null && <YAxis data={yAxis} />}
        <div ref={chartRef} className="chart">
          {tooltip != null && (
            <Tooltip
              data={tooltip}
              step={step}
              columnWG={columnWidth + columnGap}
              position={position}
              newWidth={newWidth}
              isLastPos={isLastPos}
              dataLength={dataLength}
            />
          )}
          <div className="innerWraper">
            <div className="inner" style={{ left, columnGap: newColumnGap }}>
              {children}
            </div>
          </div>
        </div>
        <div className="button_right" style={buttonHeight}>
          {maxPos > 0 && (
            <button onClick={handlePosition()} disabled={position === maxPos}>
              <ChevronRight />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

interface LegendProps {
  data: { label: string; color: string }[];
}
const Legend = ({ data }: LegendProps) => (
  <div className="legend">
    {data.map((d, ind) => (
      <div key={ind}>
        <div style={{ backgroundColor: d.color }} />
        <span>{d.label}</span>
      </div>
    ))}
  </div>
);

interface YAxisProps {
  data: number[];
}
const YAxis = ({ data }: YAxisProps) => (
  <div className="yAxis">
    {data.map((d, ind) => (
      <span key={ind}>{d}</span>
    ))}
  </div>
);

interface TooltipProps {
  data: { index: number; x: number; data: { value: number; color: string }[] };
  step: number;
  columnWG: number;
  position: number;
  newWidth: number;
  isLastPos: boolean;
  dataLength: number;
}
const Tooltip = ({
  data,
  step,
  columnWG,
  position,
  newWidth,
  isLastPos,
  dataLength,
}: TooltipProps) => {
  if (
    !isLastPos &&
    (data.index >= step * (position + 1) || data.index < step * position)
  ) {
    return null;
  }

  let left =
    data.x - newWidth * position + (newWidth - step * columnWG) * position;
  if (isLastPos) {
    const dataWidth = dataLength * columnWG;
    const stepWG = step * columnWG;
    left = left - dataWidth + stepWG * (position + 1);
    if (data.index < dataLength - step) {
      return null;
    }
  }

  return (
    <div className="chartTooltip" style={{ left }}>
      {data.data.map((d, ind) => (
        <div key={ind}>
          <span>{d.value}</span>
          <div style={{ backgroundColor: d.color }} />
        </div>
      ))}
    </div>
  );
};
