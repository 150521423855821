import { useTranslation } from 'react-i18next';

import './KPIs.scss';
import { useKpis } from 'api/use-kpis';
import { KpiBox } from './kpis/kpiBox';
import { SkeletonKpis } from './kpis/skeleton-kpis';
import { KpiDtoType } from 'domain/BackendService';

const titleKpis = [
  KpiDtoType.CLOSED,
  KpiDtoType.OPERATIONS,
  KpiDtoType.PENDING,
  KpiDtoType.RESOURCE,
  KpiDtoType.WASTE,
];
export const KPIs = () => {
  const { t } = useTranslation();
  const { loading, data } = useKpis();
  if (data === null) return null;
  return (
    <div id="kpis">
      <h2>{t('dashboard.kpis.title')}</h2>
      <div className="wrapper">
        {loading || data === null ? (
          <>
            {titleKpis.map((item, index) => (
              <SkeletonKpis type={item} key={index} />
            ))}
          </>
        ) : (
          <>
            {data.map((info, index) => (
              <KpiBox infoType={info.type!} value={info.value!} key={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
