import {
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from "./../../authConfig";

export const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      setTimeout(() => {
        instance.loginRedirect(loginRequest);
      }, 1000);
    }
  }, [isAuthenticated, instance]);

  return <></>;
};
