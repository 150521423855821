import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import './Filters.scss';
import { InputDate } from 'components';
import { Filter } from 'domain/models';
import { useCallback } from 'react';

interface Props {
  filter: Filter;
  setFilter: (filter: Filter) => void;
}
export const Filters = ({ filter, setFilter }: Props) => {
  const { t } = useTranslation();

  const handleChange = (name: string, value: string | number | null) => {
    if (name === 'startDate') {
      // const startDateMoment = moment(value, 'YYYY-MM-DD');
      // const min = startDateMoment.clone().add(1, 'months');
      // const max = startDateMoment.clone().add(6, 'months');
      const endDateMoment = moment(filter.endDate, 'YYYY-MM-DD');
      // if (endDateMoment.diff(min) < 0) {
      //   setFilter(prev => ({
      //     ...prev,
      //     startDate: value as string,
      //     endDate: min.format('YYYY-MM-DD'),
      //   }));
      //   return;
      // }
      // if (endDateMoment.diff(max) > 0) {
      //   setFilter(prev => ({
      //     ...prev,
      //     startDate: value as string,
      //     endDate: max.format('YYYY-MM-DD'),
      //   }));

      setFilter({
        startDate: value as string,
        endDate: endDateMoment.format('YYYY-MM-DD'),
      });
      return;
    }

    if (name === 'endDate') {
      // const endDateMoment = moment(value, 'YYYY-MM-DD');
      // const min = endDateMoment.clone().add(-1, 'months');
      // const max = endDateMoment.clone().add(-6, 'months');
      const startDateMoment = moment(filter.startDate, 'YYYY-MM-DD');
      // if (startDateMoment.diff(min) > 0) {
      //   setFilter(prev => ({
      //     ...prev,
      //     endDate: value as string,
      //     startDate: min.format('YYYY-MM-DD'),
      //   }));
      //   return;
      // }
      // if (startDateMoment.diff(max) < 0) {
      //   setFilter(prev => ({
      //     ...prev,
      //     endDate: value as string,
      //     startDate: max.format('YYYY-MM-DD'),
      //   }));
      //   return;
      // }
      setFilter({
        endDate: value as string,
        startDate: startDateMoment.format('YYYY-MM-DD'),
      });
    }
  };

  const isDayBlockedStart = useCallback((day: Moment) => {
    const today = moment();
    return day.isAfter(today, 'day');
  }, []);

  const isDayBlockedEnd = useCallback(
    (day: Moment) => {
      const today = moment();
      const startDateRange = moment(filter.startDate, 'YYYY-MM-DD');
      return day.isAfter(today, 'day') || day.isBefore(startDateRange, 'day');
    },
    [filter.startDate],
  );

  return (
    <div id="filters">
      <div className="wrapper">
        <InputDate
          name="startDate"
          label={t('dashboard.filters.startDate')}
          value={filter.startDate}
          onChange={handleChange}
          isDayBlocked={isDayBlockedStart}
        />
        <InputDate
          name="endDate"
          label={t('dashboard.filters.endDate')}
          value={filter.endDate}
          onChange={handleChange}
          isDayBlocked={isDayBlockedEnd}
        />
        {/* <InputSelect
          name="project"
          label={t('dashboard.filters.project')}
          value={filter.project}
          onChange={handleChange}
          options={[
            { name: t('dashboard.filters.project-all'), value: null },
            { name: 'Proyecto Elche', value: 1 },
            { name: 'Proyecto Torrellano', value: 2 },
            { name: 'Tratamiento residuos Alc', value: 3 },
            { name: 'Planta tratamiento', value: 4 },
            // { name: 'Proyecto 5', value: 5 },
            // {
            //   name: 'Proyecto 6 Proyecto 6 Proyecto 6 Proyecto 6 Proyecto 6',
            //   value: 6,
            // },
            // { name: 'Proyecto 7', value: 7 },
            // { name: 'Proyecto 8', value: 8 },
            // { name: 'Proyecto 9', value: 9 },
          ]}
        /> */}
      </div>
    </div>
  );
};
