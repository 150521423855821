import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Box.scss';
import './Waste.scss';
import { Box } from './Box';
import { Filter } from 'domain/models';
import { useWasteClassification } from 'api/use-waste-classification';
import { BoxLoading } from './BoxLoading';
import { BoxEmpty } from './BoxEmpty';
import { Info } from './Info';

const COLUMN_WIDTH = 36;
const COLUMN_GAP = 16;
const COLUMN_MAX_HEIGHT = 180;

const COLORS_ARRAY = [
  '#00E697',
  '#33D6AE',
  '#56C7C6',
  '#79B8DD',
  '#9CA9F4',
  '#BF9AE9',
  '#E28BD9',
  '#FF7DC8',
  '#d078ff',
  '#ab84ff',
];
export interface WasteDto {
  label: string;
  height: number;
  number: number;
  color: string;
}
interface Props {
  filter: Filter;
}
export const Waste = ({ filter }: Props) => {
  const { t } = useTranslation();
  const [dataMaxHeight, setDataMaxHeight] = useState(1);
  const { loading, data } = useWasteClassification(
    filter.startDate,
    filter.endDate,
  );

  useEffect(() => {
    setDataMaxHeight(Math.max(...data.map(d => d.count!)));
  }, [data]);
  const empty = data.length === 0 ? true : false;
  return (
    <>
      <div className="chartBox" style={{ height: 326 }}>
        <label>{t('dashboard.charts.waste.title')}</label>
        <Info
          title={t('dashboard.charts.waste.title')}
          text={t('dashboard.charts.waste.info')}
        />

        {loading ? (
          <BoxLoading />
        ) : empty ? (
          <BoxEmpty />
        ) : (
          <Box
            columnGap={COLUMN_GAP}
            columnWidth={COLUMN_WIDTH}
            dataLength={data.length}
            yLabel={t('dashboard.charts.waste.yLabel')}
            yBottom="LER"
          >
            {data.map((d, index) => {
              const height = Math.round(
                (COLUMN_MAX_HEIGHT * d.count!) / dataMaxHeight,
              );
              return (
                <div
                  key={index}
                  className="wasteColumn"
                  style={{ width: COLUMN_WIDTH }}
                >
                  <div style={{ width: COLUMN_WIDTH }}>
                    <div className="number">{d.count}</div>
                    <div
                      className="color"
                      style={{
                        height,
                        backgroundColor:
                          COLORS_ARRAY[index > 0 ? index % 10 : index],
                      }}
                    />
                  </div>
                  <div className="label">
                    <p>{d.lerCode?.split(' ')[0]}</p>
                    <p>{d.lerCode?.split(' ')[1]}</p>
                    <p>{d.lerCode?.split(' ')[2]}</p>
                  </div>
                </div>
              );
            })}
          </Box>
        )}
      </div>
    </>
  );
};
