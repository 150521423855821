import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useProcessEvolution } from 'api/use-process-evolution';
import { EvolutionDto, Filter } from 'domain/models';
import { EvolutionGeneric } from './EvolutionGeneric';
import { Info } from './Info';
import { BoxLoading } from './BoxLoading';
import { BoxEmpty } from './BoxEmpty';

const X1_COLOR = '#00E697';
const X2_COLOR = '#7A48FF';
const X3_COLOR = '#F49A56';

interface Props {
  filter: Filter;
}

export const Evolution = ({ filter }: Props) => {
  const { t } = useTranslation();
  const { loading, data } = useProcessEvolution(
    filter.startDate,
    filter.endDate,
  );

  const DATA: EvolutionDto[] = data
    .sort((a, b) => moment(a.date).valueOf() - moment(b.date!).valueOf())
    .map(info => {
      const dayMoment =
        info.date != null
          ? moment(info.date).format('DD MMM').toString()
          : moment().toString();
      // const day = dayMoment.month() * 100 + dayMoment.date();
      return {
        day: dayMoment,
        x1: info.opened ?? 0,
        x2: info.closed ?? 0,
        x3: info.pending ?? 0,
      };
    });

  let sumaX1 = 0;
  let sumaX2 = 0;
  let sumaX3 = 0;

  for (const clave in DATA) {
    if (DATA.hasOwnProperty(clave)) {
      const info = DATA[clave];
      sumaX1 += info.x1;
      sumaX2 += info.x2;
      sumaX3 += info.x3;
    }
  }
  const empty = sumaX1 === 0 && sumaX2 === 0 && sumaX3 === 0 ? true : false;
  return (
    <>
      <div className="chartBox" style={{ height: 340 }}>
        <label>{t('dashboard.charts.evolution.title')}</label>
        <Info
          title={t('dashboard.charts.evolution.title')}
          text={t('dashboard.charts.evolution.info')}
        />
        {loading ? (
          <BoxLoading />
        ) : empty ? (
          <BoxEmpty />
        ) : (
          <EvolutionGeneric
            loading={loading}
            evolution={DATA}
            yLabel={t('dashboard.charts.evolution.yLabel')}
            yBottom={t('dashboard.charts.evolution.yBottom')}
            colors={{ x1: X1_COLOR, x2: X2_COLOR, x3: X3_COLOR }}
            legend={[
              {
                label: t('dashboard.charts.evolution.xLabel1'),
                color: X1_COLOR,
              },
              {
                label: t('dashboard.charts.evolution.xLabel2'),
                color: X2_COLOR,
              },
              {
                label: t('dashboard.charts.evolution.xLabel3'),
                color: X3_COLOR,
              },
            ]}
          />
        )}
      </div>
    </>
  );
};
