import { useTranslation } from 'react-i18next';

import { KPIs, Charts, ActivityTable } from './components';

export const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div id="dashboard">
      <h1>{t('dashboard.title')}</h1>
      <KPIs />
      <Charts />
      <ActivityTable />
    </div>
  );
};
