// import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './../KPIs.scss';
// import { Info } from '../chartsComponents/Info';
import { KpiDtoType } from 'domain/BackendService';
import { LoadingAnimationKpis } from 'components';

interface Props {
  type: KpiDtoType;
}
export const SkeletonKpis = ({ type }: Props) => {
  const { t } = useTranslation();
  // const [infoClick, setInfoClick] = useState<string>();
  // const handleInfoClick = (type: string) => () => {
  //   setInfoClick(type);
  // };
  return (
    <div className="kpi">
      <label>{t(`dashboard.kpis.${type}.title`)}</label>
      <div className="box">
        {/* <Info
          title={t(`dashboard.kpis.${type}.title`)}
          text={t(`dashboard.kpis.${type}.info`)}
          isKpi
          onShow={handleInfoClick(type)}
          show={type === infoClick}
        /> */}
        <LoadingAnimationKpis />
      </div>
    </div>
  );
};
