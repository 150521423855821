import { LoadingAnimation } from 'components';
import './Box.scss';

interface Props {
  height?: string | number;
  onWidth?: (width: number) => void;
}
export const BoxLoading = ({ height = '100%', onWidth }: Props) => {
  return (
    <div
      style={{
        width: '100%',
        height,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <LoadingAnimation />
    </div>
  );
};
