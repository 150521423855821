import { Outlet } from 'react-router-dom';

import { Container, Header } from './components';

export const App = () => (
  <>
    <Header />
    <Container>
      <Outlet />
    </Container>
  </>
);
