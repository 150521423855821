import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import './Header.scss';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { AuthContext } from 'context/AuthContext';
import { ReactComponent as IconUser } from 'assets/icons/User.svg';
import { ReactComponent as IconAlarm } from 'assets/icons/Alarm.svg';
import { ReactComponent as IconSettings } from 'assets/icons/Settings.svg';
import { ReactComponent as IconQuestion } from 'assets/icons/Question.svg';
import { ReactComponent as IconWorld } from 'assets/icons/World.svg';
import { ReactComponent as IconExit } from 'assets/icons/Exit.svg';
import { ReactComponent as IconDashboard } from 'assets/icons/Dashboard.svg';
import { ReactComponent as IconProjects } from 'assets/icons/Projects.svg';
import { ReactComponent as IconUsers } from 'assets/icons/Users.svg';
import { ReactComponent as IconNav } from 'assets/icons/Nav.svg';
import { ReactComponent as IconClose } from 'assets/icons/Close.svg';
import { ReactComponent as IconSelect } from 'assets/icons/Select.svg';
import { Link } from 'react-router-dom';

export const Header = () => {
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);

  const [language, setLanguage] = useState(i18n.language.slice(0, 2));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggleMenuLanguage, setToggleMenuLanguage] = useState(false);
  const [activeButtonLanguage, setActiveButtonLanguage] = useState(
    i18n.language,
  );
  const languages = [
    { id: 'es', name: t('header.es') },
    { id: 'en', name: t('header.en') },
  ];
  const nav = [{ name: 'dashboard' }, { name: 'projects' }, { name: 'users' }];
  const TypeIcon: { [key: string]: JSX.Element } = {
    dashboard: <IconDashboard className="icon" />,
    projects: <IconProjects className="icon" />,
    users: <IconUsers className="icon" />,
  };

  const logout = () => {
    authContext.signOut();
  };

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [activeButton, setActiveButton] = useState('dashboard');

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };
  const toggleLanguage = () => {
    setToggleMenuLanguage(!toggleMenuLanguage);
  };
  const clickedButtonHandler = (link: string) => {
    setActiveButton(link);
  };

  const clickedButtonLanguageOption = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    setActiveButtonLanguage(lang);
  };

  const NavSettings = () => (
    <div className="nav_settings">
      <button>
        <IconQuestion className="iconBig" />
      </button>
      <button>
        <IconAlarm className="iconBig" />
      </button>
      <button>
        <IconSettings className="iconBig" />
      </button>
    </div>
  );

  return (
    <div className="nav">
      <div className="nav_logo">
        <Logo />
      </div>
      {(toggleMenu || screenWidth > 1023) && (
        <>
          <div className="nav_container">
            <div className="nav_nav">
              {nav.map((navOptions, index) => (
                <Link
                  to={navOptions.name}
                  onClick={() => clickedButtonHandler(navOptions.name)}
                  className={
                    activeButton === navOptions.name
                      ? 'nav_nav_link nav_nav_link_active'
                      : 'nav_nav_link'
                  }
                  key={index}
                >
                  {TypeIcon[navOptions.name]}
                  {t('header.' + navOptions.name)}
                </Link>
              ))}
            </div>
            {screenWidth > 1023 && !toggleMenu ? <NavSettings /> : ''}
            <div className="nav_language">
              <button onClick={toggleLanguage} className="button_language">
                <IconWorld className="iconWorld" />
                {t('header.language')}: <span>{language} </span>
                <IconSelect className="iconSelect" />
              </button>
              {toggleMenuLanguage && (
                <div className="nav_MenuLanguage">
                  {languages.map(lang => (
                    <button
                      name={lang.id}
                      onClick={() => clickedButtonLanguageOption(lang.id)}
                      className={
                        activeButtonLanguage === lang.id
                          ? 'button_languageOption button_languageOption_active'
                          : 'button_languageOption'
                      }
                    >
                      {lang.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="nav_user">
              <div className="nav_user_text">
                <p>Usuario</p>
                <p>Empresa</p>
              </div>
              <button className="button_iconUser">
                <IconUser className="iconUser" />
              </button>
            </div>
            <div className="nav_exit">
              <button onClick={logout}>
                <IconExit className="iconExit" />
                {toggleMenu === false ? '' : 'Cerrar sesión'}
              </button>
            </div>
          </div>
        </>
      )}
      {screenWidth < 1023 ? <NavSettings /> : ''}
      <div className="nav_burger">
        <button onClick={toggleNav}>
          {toggleMenu === false ? (
            <IconNav />
          ) : (
            <IconClose className="iconClose" />
          )}
        </button>
      </div>
    </div>
  );
};
