import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './../KPIs.scss';
import { Info } from '../chartsComponents/Info';

interface Props {
  infoType: string;
  value: number | undefined;
}
export const KpiBox = ({ infoType, value }: Props) => {
  const { t } = useTranslation();
  const [infoClick, setInfoClick] = useState<string>();
  const handleInfoClick = (type: string) => () => {
    setInfoClick(type);
  };

  return (
    <div key={infoType} className="kpi">
      <label>{t(`dashboard.kpis.${infoType}.title`)}</label>
      <div className="box">
        <Info
          title={t(`dashboard.kpis.${infoType}.title`)}
          text={t(`dashboard.kpis.${infoType}.info`)}
          isKpi
          onShow={handleInfoClick(infoType)}
          show={infoType === infoClick}
        />
        <div className="number">{value}</div>
        <div className="text">{t(`dashboard.kpis.${infoType}.units`)}</div>
      </div>
    </div>
  );
};
