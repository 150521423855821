import { useTranslation } from 'react-i18next';

import moment from 'moment';
import './Charts.scss';
import { Evolution, Processes, Shares, Waste } from './chartsComponents';
import { Filters } from './Filters';
import { useCallback, useState } from 'react';
import { Filter } from 'domain/models';

export const Charts = () => {
  const { t } = useTranslation();
  const [filters, setFilter] = useState<Filter>({
    startDate:
      localStorage.getItem('filterStartDate') ??
      moment().startOf('month').format('YYYY-MM-DD'),
    endDate:
      localStorage.getItem('filterEndDate') ?? moment().format('YYYY-MM-DD'),
  });

  const saveFilter = useCallback((filter: Filter) => {
    setFilter(filter);
    localStorage.setItem('filterStartDate', filter.startDate);
    localStorage.setItem('filterEndDate', filter.endDate);
  }, []);

  return (
    <div id="charts">
      <h2>{t('dashboard.charts.title')}</h2>
      <Filters filter={filters} setFilter={saveFilter} />
      <div className="wrapper">
        <Waste filter={filters} />
        <Processes filter={filters} />
        <Evolution filter={filters} />
        <Shares filter={filters} />
      </div>
    </div>
  );
};
