import { useTranslation } from 'react-i18next';
import { EvolutionDto, Filter } from 'domain/models';
import moment from 'moment';
import { useActionsAmount } from 'api/use-actions-amount';
import { EvolutionGeneric } from './EvolutionGeneric';
import { BoxLoading } from './BoxLoading';
import { BoxEmpty } from './BoxEmpty';
import { Info } from './Info';

const X1_COLOR = '#78A5FF';
const X2_COLOR = '#78FFE5';
const X3_COLOR = '#FFB2A4';
const X4_COLOR = '#FF7DC8';

interface Props {
  filter: Filter;
}

export const Shares = ({ filter }: Props) => {
  const { t } = useTranslation();
  const { loading, data } = useActionsAmount(filter.startDate, filter.endDate);

  const DATA: EvolutionDto[] = data
    .sort((a, b) => moment(a.date).valueOf() - moment(b.date!).valueOf())
    .map(info => {
      const dayMoment =
        info.date != null
          ? moment(info.date).format('DD MMM').toString()
          : moment().toString();
      // const day = dayMoment.month() * 100 + dayMoment.date();
      return {
        day: dayMoment,
        x1: info.categoryRU ?? 0,
        x2: info.categoryRV ?? 0,
        x3: info.categoryD ?? 0,
        x4: info.categoryA ?? 0,
      };
    });
  let sumaX1 = 0;
  let sumaX2 = 0;
  let sumaX3 = 0;
  let sumaX4 = 0;

  for (const clave in DATA) {
    if (DATA.hasOwnProperty(clave)) {
      const info = DATA[clave];
      sumaX1 += info.x1;
      sumaX2 += info.x2;
      sumaX3 += info.x3;
      sumaX4 += info.x3;
    }
  }

  const empty =
    sumaX1 === 0 && sumaX2 === 0 && sumaX3 === 0 && sumaX4 === 0 ? true : false;

  return (
    <>
      <div className="chartBox" style={{ height: 340 }}>
        <label>{t('dashboard.charts.shares.title')}</label>
        <Info
          title={t('dashboard.charts.shares.title')}
          text={t('dashboard.charts.shares.info')}
        />

        {loading ? (
          <BoxLoading />
        ) : empty ? (
          <BoxEmpty />
        ) : (
          <EvolutionGeneric
            loading={loading}
            evolution={DATA}
            yLabel={t('dashboard.charts.shares.yLabel')}
            yBottom={t('dashboard.charts.shares.yBottom')}
            colors={{ x1: X1_COLOR, x2: X2_COLOR, x3: X3_COLOR, x4: X4_COLOR }}
            legend={[
              { label: t('dashboard.charts.shares.xLabel1'), color: X1_COLOR },
              { label: t('dashboard.charts.shares.xLabel2'), color: X2_COLOR },
              { label: t('dashboard.charts.shares.xLabel3'), color: X3_COLOR },
              { label: t('dashboard.charts.shares.xLabel4'), color: X4_COLOR },
            ]}
          />
        )}
      </div>
    </>
  );
};
