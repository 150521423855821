import { useEffect, useState } from 'react';
import { LineChart, Line, YAxis, Tooltip, XAxis } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import './EvolutionGeneric.scss';
import { Box } from './Box';
import { EvolutionDto } from 'domain/models';

const COLUMN_WIDTH = 30;
const COLUMN_GAP = 0;

interface Props {
  loading?: boolean;
  evolution: EvolutionDto[];

  yLabel: string;
  yBottom: string;
  colors: { x1: string; x2: string; x3: string; x4?: string };
  legend: { label: string; color: string }[];
}
export const EvolutionGeneric = ({
  loading,
  evolution,
  yLabel,
  yBottom,
  colors,
  legend,
}: Props) => {
  const [yAxis, setYAxis] = useState<number[]>([]);
  useEffect(() => {
    let dataMax = 0;
    if (evolution.length !== 0) {
      dataMax = Math.max(
        ...evolution.map(d => Math.max(d.x1, d.x2, d.x3, d.x4 ?? 0)),
      );
    }
    dataMax = calcNewDataMax(dataMax);

    const newYAxis = [];
    for (let i = 0; i <= dataMax && dataMax > 0; i += dataMax / 4) {
      newYAxis.push(i);
    }
    setYAxis(newYAxis);
  }, [evolution]);

  const [tooltip, setTooltip] = useState<
    | {
        index: number;
        day: string;
        x: number;
        data: { value: number; color: string }[];
      }
    | undefined
  >(undefined);

  const handleMouseMove = (nextState: CategoricalChartState, event: any) => {
    const {
      isTooltipActive,
      activePayload,
      activeCoordinate,
      activeTooltipIndex,
    } = nextState;

    if (
      isTooltipActive &&
      activePayload != null &&
      activePayload.length > 0 &&
      activeCoordinate?.x != null &&
      activeTooltipIndex != null
    ) {
      const data = activePayload
        .map(aP => ({
          value: aP.payload[aP.dataKey],
          color: aP.color,
        }))
        .filter(d => d.value != null);

      const payload: EvolutionDto = activePayload[0].payload;

      setTooltip({
        index: activeTooltipIndex,
        day: payload.day,
        x: activeCoordinate.x,
        data,
      });
    } else {
      setTooltip(undefined);
    }
  };

  const handleMouseLeave = () => {
    setTooltip(undefined);
  };

  const [boxWidth, setBoxWidth] = useState(80);
  const step = Math.floor(
    (boxWidth + COLUMN_GAP) / (COLUMN_WIDTH + COLUMN_GAP),
  );
  const maxPos = step > 0 ? Math.ceil(evolution.length / step) - 1 : 0;
  let columnGap = COLUMN_GAP;
  if (maxPos === 0) {
    columnGap =
      (boxWidth - evolution.length * COLUMN_WIDTH) / (evolution.length - 1);
  }

  return (
    <Box
      columnGap={COLUMN_GAP}
      columnWidth={COLUMN_WIDTH}
      dataLength={evolution.length}
      yLabel={yLabel}
      yBottom={yBottom}
      legend={legend}
      yAxis={yAxis}
      tooltip={tooltip}
      isEvolution
      onWidth={setBoxWidth}
    >
      <div className="chartEvolution">
        <LineChart
          // width={evolution.length * COLUMN_WIDTH}
          width={
            evolution.length * COLUMN_WIDTH + (evolution.length - 1) * columnGap
          }
          height={176}
          data={evolution}
          margin={{ top: 7, right: 8, bottom: 7, left: 7 }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <XAxis dataKey="day" hide />
          <YAxis type="number" width={2} domain={[0, calcNewDataMax]} hide />
          <Tooltip
            content={() => null}
            cursor={{ stroke: 'black', strokeDasharray: '2,2' }}
            isAnimationActive={false}
          />
          <Line type="monotone" dataKey="x1" stroke={colors.x1} dot={false} />
          <Line type="monotone" dataKey="x2" stroke={colors.x2} dot={false} />
          <Line type="monotone" dataKey="x3" stroke={colors.x3} dot={false} />
          <Line type="monotone" dataKey="x4" stroke={colors.x4} dot={false} />
        </LineChart>
        <div
          className="XAxis"
          // style={{ width: evolution.length * COLUMN_WIDTH }}
        >
          {evolution.map((e, ind) => {
            const showDay = e.day !== '' && e.day;
            const selected = e.day === tooltip?.day;
            return (
              <div key={ind} className={selected ? 'selected' : undefined}>
                {showDay || selected ? e.day : '|'}
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

const calcNewDataMax = (dataMax: number) => {
  let newDataMax = Math.ceil(dataMax / 4) * 4;
  while (newDataMax % 1 !== 0) {
    newDataMax += 4;
  }
  return newDataMax;
};
