import { RefObject, useEffect, useRef, useState } from 'react';

export const useIsOverflow = (): [RefObject<HTMLDivElement>, boolean] => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element == null) return;

    const observer = new ResizeObserver(entries => {
      setIsOverflow(element.scrollWidth > element.clientWidth);
    });
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [ref]);

  return [ref, isOverflow];
};
