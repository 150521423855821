import './LoadingAnimationKpis.scss';
export const LoadingAnimationKpis = () => {
  return (
    <div className="lds-roller-kpi">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      {/* <div></div>
      <div></div> */}
    </div>
  );
};
