import { PublicClientApplication } from "@azure/msal-browser";
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { App } from './App';
import { Login, Dashboard } from 'pages';
import { AuthProvider } from "context/AuthContext";
import { AxiosProvider } from "context/AxiosContext";

interface Props {
  msalInstance: PublicClientApplication;
}

export const Navigation = ({ msalInstance }: Props) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <AuthProvider>
          <AxiosProvider>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </AxiosProvider>
        </AuthProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};
