import { useState } from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';

import './Info.scss';

interface Props {
  title: string;
  text: string;
  isKpi?: boolean;
  onShow?: () => void;
  show?: boolean;
}
export const Info = ({
  title,
  text,
  isKpi = false,
  onShow,
  show = true,
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (onShow) onShow();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        className={`infoButton${isKpi ? ' infoButtonKpi' : ''}`}
        onClick={handleOpen}
      >
        <InfoIcon />
      </button>
      {open && show && (
        <div className={`infoText${isKpi ? ' infoTextKpi' : ''}`}>
          <label>{title}</label>
          <button onClick={handleClose}>
            <CloseIcon />
          </button>
          <div className="text">
            <div>{text}</div>
          </div>
        </div>
      )}
    </>
  );
};
