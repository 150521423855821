import { useTranslation } from 'react-i18next';

import './ActivityTable.scss';
import { useIsOverflow } from 'hooks';
import { useActivities } from 'api/use-activities';

const colorBlock = (code?: string) => {
  if (code == null) {
    return '';
  }
  switch (code.charAt(0)) {
    case 'A':
      return '#F3F2EE';
    case 'D':
      return '#FFB2A4';
    case 'R':
    default:
      return code[2] != null && code[2] === '4' ? '#78A5FF' : '#78FFE5';
  }
};
export const ActivityTable = () => {
  const { t } = useTranslation();
  const [wrapperRef, isOverflow] = useIsOverflow();
  const { data } = useActivities();
  return (
    <div id="activities">
      <h2>{t('dashboard.activities.title')}</h2>

      <div
        ref={wrapperRef}
        className={`wrapper${isOverflow ? ' wrapperHide' : ''}`}
      >
        <table className="activityTable activityTableMobile">
          <thead>
            <tr>
              <th scope="col">{t('dashboard.activities.id')}</th>
              <th scope="col">{t('dashboard.activities.waste')}</th>
              <th scope="col" style={{ minWidth: 116 }}>
                {t('dashboard.activities.project')}
              </th>
              <th scope="col" style={{ maxWidth: 116 }}>
                {t('dashboard.activities.startDate')}
              </th>
              <th scope="col" style={{ maxWidth: 124 }}>
                {t('dashboard.activities.lastDate')}
              </th>
              <th scope="col">{t('dashboard.activities.firstOperation')}</th>
              <th scope="col" style={{ maxWidth: 116 }}>
                {t('dashboard.activities.lastOperation')}
              </th>
              <th scope="col" style={{ maxWidth: 98 }}>
                {t('dashboard.activities.nOperations')}
              </th>
              <th scope="col">{t('dashboard.activities.duration')}</th>
              <th scope="col">{t('dashboard.activities.chain')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((act, index) => (
              <tr key={index}>
                <td>{act.chainCode}</td>
                <td className="left">{act.type}</td>
                <td className="left">{act.projectName}</td>
                <td className="activityDate">{act.startDate}</td>
                <td className="activityDate">{act.lastModifiedDate}</td>
                <td>
                  <div className="centerOperation">
                    <Operation
                      value={act.firstOperationCode!}
                      operationCode={act.firstOperationCode!}
                    />
                  </div>
                </td>
                <td>
                  <div className="centerOperation">
                    <Operation
                      value={act.lastOperationCode!}
                      operationCode={act.lastOperationCode!}
                    />
                  </div>
                </td>
                <td>{act.numOperations}</td>
                <td>{act.duration} días</td>
                <td>
                  <a
                    href={`https://gentle-pond-0fad26d03.2.azurestaticapps.net/${act.type}/${act.chainCode}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('dashboard.activities.chainText')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="wrapperCard"
        style={{ display: isOverflow ? 'flex' : 'none' }}
      >
        {data.map((act, index) => (
          <div key={index}>
            <div className="cardId">
              <div>
                <strong>{t('dashboard.activities.id')}</strong>
                {act.id}
              </div>
              <a href={`#${act.id}`}>{t('dashboard.activities.chainText')}</a>
            </div>
            <div>
              <strong>{t('dashboard.activities.waste')}</strong>
              {act.type}
            </div>
            <div>
              <strong>{t('dashboard.activities.project')}</strong>
              {act.projectName}
            </div>
            <div className="activityDate">
              <strong>{t('dashboard.activities.startDate')}</strong>
              {act.startDate}
            </div>
            <div className="activityDate">
              <strong>{t('dashboard.activities.lastDate')}</strong>
              {act.lastModifiedDate}
            </div>
            <div className="operation">
              <div>
                <strong>{t('dashboard.activities.firstOperation')}</strong>
                <Operation
                  value={act.firstOperation}
                  operationCode={act.firstOperationCode!}
                />
              </div>
              <div>
                <strong>{t('dashboard.activities.lastOperation')}</strong>
                <Operation
                  value={act.lastOperation}
                  operationCode={act.lastOperationCode!}
                />
              </div>
            </div>
            <div>
              <strong>{t('dashboard.activities.nOperations')}</strong>
              {act.numOperations}
            </div>
            <div>
              <strong>{t('dashboard.activities.duration')}</strong>
              {act.duration}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Operation = ({
  value,
  operationCode,
}: {
  value: string;
  operationCode: string;
}) => (
  <div
    className="activityOperation"
    style={{ backgroundColor: colorBlock(operationCode) }}
  >
    {value}
  </div>
);
