import { ActivityDto } from 'domain/BackendService';
import { useAsync } from 'hooks';
import { useAuthApiService } from 'services/ApiService';

export function useActivities() {
  const ApiClient = useAuthApiService();

  const apiCall = (signal: AbortSignal) => ApiClient.getActivity(signal as any);

  return useAsync<ActivityDto[]>({
    apiCall,
    initialData: [],
    dependencies: [],
  });
}
