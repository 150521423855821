import { useEffect, useState } from 'react';

export const useAsync = <T>({
  apiCall,
  initialData,
  dependencies = [],
}: {
  apiCall: (signal: AbortSignal) => Promise<T>;
  initialData: T;
  dependencies?: any[];
}) => {
  const [data, setData] = useState<T>(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const getData = async () => {
      setLoading(true);
      setError(undefined);
      try {
        const dataResponse = await apiCall(controller.signal);
        if (isActive) {
          setData(dataResponse);
          setError(undefined);
        }
      } catch (error: any) {
        if (isActive) setError(error.message);
      }
      if (isActive) setLoading(false);
    };

    getData();

    return () => {
      isActive = false;
      setLoading(false);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return { data, loading, error };
};
