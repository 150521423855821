import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import React, {createContext, useCallback, useEffect, useState} from 'react';

type AuthStateType = {
    ready: boolean,
    idToken: string | null | undefined;
};

export const AuthInitialState = {
    ready: false,
    idToken: null,
} as AuthStateType;

interface AuthContextProps{
    children: React.ReactNode
}

const AuthContext = createContext({
    authState: {} as AuthStateType,
    refreshToken: async () => "",
    signOut: () => {},
});

const AuthProvider = ({ children }: AuthContextProps) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [ authState, setAuthState ] = useState<AuthStateType>(AuthInitialState);

    const getToken = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            const account = instance.getAllAccounts()[0];
            const request = {
                scopes: [""],
                account: account,
            };
    
            instance
                .acquireTokenSilent(request)
                .then(function (response) {
                    resolve(response.idToken);
                })
                .catch(function (error) {
                    console.log('error', error);
                    //Acquire token silent failure, and send an interactive request
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(request)
                            .then(function (response) {
                                resolve(response.idToken);
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                console.log('[ERROR]', error);
                                reject(error);
                            });
                    }
                });
        });
    }

    const refreshToken = async () => {
        const idToken = await getToken();
        setAuthState({
            ready: true,
            idToken: idToken,
        });
        return idToken;
    }

    const signOut = () => {
        instance.logoutRedirect({})
        setAuthState({
            ready: false,
            idToken: null,
        });
    };

    useEffect(() => {
        if (authState.ready) {
            return;
        }

        getToken().then((idToken) => {
            setAuthState({
                ready: true,
                idToken: idToken,
            });
        })
    }, []);

    return (
        (!authState.ready) 
            ? <></> 
            : <AuthContext.Provider 
                value={{
                    authState, 
                    refreshToken,
                    signOut,
                }}>
                {children}
            </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};
