import './Box.scss';

import styled from 'styled-components';

import { ReactComponent as EmptyIcon } from 'assets/icons/empty.svg';
const EmptyContainer = styled.div`
  margin: 5% 10% 5% 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  box-sizing: content;
  > div {
    flex: 1;
    box-sizing: content;
    height: 60%;
    border: 0.5px solid #dee1e3;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
const Label = styled.p`
  color: gray;
  font-weight: 500;
  display: block;
  padding-top: 25px;
`;
export const BoxEmpty = () => {
  return (
    <EmptyContainer>
      <div>
        <EmptyIcon />
        <Label>No hay datos disponibles</Label>
      </div>
    </EmptyContainer>
  );
};
