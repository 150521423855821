import { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import './InputDate.scss';
import { ReactComponent as Calendar } from 'assets/icons/Calendar.svg';

interface Props {
  label: string;
  name: string;
  value: string | null;
  onChange: (name: string, value: string) => void;
  isDayBlocked?: ((day: moment.Moment) => boolean) | undefined;
}
export const InputDate = ({
  name,
  label,
  value,
  onChange,
  isDayBlocked,
}: Props) => {
  const { i18n } = useTranslation();
  const [key, setKey] = useState(i18n.language);
  useEffect(() => {
    setKey(i18n.language);
  }, [i18n.language]);

  const handleChange = (date: Moment | null) => {
    if (date != null) {
      onChange(name, date.format('YYYY-MM-DD'));
    }
  };

  const [focused, setFocused] = useState(false);

  return (
    <div className="input_date">
      <label>{label}</label>
      <SingleDatePicker
        key={key}
        id={name}
        numberOfMonths={1}
        onDateChange={handleChange}
        onFocusChange={({ focused }) => setFocused(focused)}
        focused={focused}
        date={value != null ? moment(value, 'YYYY-MM-DD') : moment()}
        hideKeyboardShortcutsPanel
        isOutsideRange={() => false}
        customInputIcon={<Calendar />}
        orientation="horizontal"
        withPortal
        readOnly
        displayFormat={'DD-MM-YYYY'}
        //para bloquear los próximos días al presente
        isDayBlocked={isDayBlocked}
      />
    </div>
  );
};
