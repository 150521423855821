import { useContext } from 'react';
import { AxiosContext } from 'context/AxiosContext';
import { Client } from 'domain/BackendService';

const BASE_URL = 'https://api.traceparent.com';

export const useAuthApiService = () => {
  const { authAxios } = useContext(AxiosContext);

  return new Client(BASE_URL, authAxios);
};
