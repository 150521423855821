import { ActionsDto } from 'domain/BackendService';
import { useAsync } from 'hooks';
import { useAuthApiService } from 'services/ApiService';

export function useActionsAmount(start: string, end: string) {
  const ApiClient = useAuthApiService();

  const apiCall = (signal: AbortSignal) =>
    ApiClient.getActionsAmount(start, end, signal as any);

  return useAsync<ActionsDto[]>({
    apiCall,
    initialData: [],
    dependencies: [start, end],
  });
}
