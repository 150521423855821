import { ReactNode } from 'react';

import './Container.scss';

interface Props {
  children: ReactNode;
}
export const Container = ({ children }: Props) => (
  <div id="container">{children}</div>
);
