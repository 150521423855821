import axios, { AxiosRequestConfig } from 'axios';
import React, { createContext, useContext } from 'react';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthContext } from './AuthContext';

interface AxiosContextProps {
  children: React.ReactNode;
}

const AxiosContext = createContext({
  authAxios: axios.create(),
  publicAxios: axios.create(),
});

const BASE_URL = 'https://api.traceparent.com';
const AXIOS_TIMEOUT = 5000;

const AxiosProvider = ({ children }: AxiosContextProps) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL: BASE_URL,
    timeout: AXIOS_TIMEOUT,
  });

  const publicAxios = axios.create({
    baseURL: BASE_URL,
    timeout: AXIOS_TIMEOUT,
  });

  authAxios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      config.headers = config.headers ?? {};

      if (!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${authContext.authState.idToken}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  const refreshAuthLogic = (failedRequest: any) => {
    return authContext.refreshToken().then(async newToken => {
      failedRequest.response.config.headers.Authorization = `Bearer ${newToken}`;
      return Promise.resolve();
    });
  };

  createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {});

  return (
    <AxiosContext.Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosProvider };
