import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { SwaggerException } from 'domain/BackendService';

export default class HttpService {
  transformOptions(options: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const signal = options.cancelToken as any as AbortSignal;
    const newOptions = { ...options, signal, cancelToken: undefined };
    return Promise.resolve(newOptions);
  }

  transformResult<U>(
    url: string,
    response: AxiosResponse,
    processor: (response: AxiosResponse) => U,
  ) {
    const status = response.status;
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<U>(_responseText);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        'An unexpected server error occurred.',
        status,
        _responseText,
        response.headers,
      );
    }
    return Promise.resolve<U>(null as any);
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any,
): any {
  if (result !== null && result !== undefined) throw result;
  else throw new SwaggerException(message, status, response, headers, null);
}
